export const BYMONTH = [
  {
    period: "Spring",
    events: ["All: Mushroom and Berry Foraging"],
  },
  {
    period: "March",
    events: ["End: Philly Theater Week"]
  },
  {
    period: "April",
    events: ["Early: Media Film Festival", "Early: Lancaster Zine Fest", "Early: Shofuso Cherry Blossom Festival", "End: Book Corner Spring Book Sale"]
  },
  {
    period: "May",
    events: ["Early: West Philly Zine Fest", "End: Philadelphia Latino Film Festival", "End: Hang Free Spring"]
  },
  {
    period: "Summer",
    events: ["All: Berry Foraging", "All: Movies on the Block", "All: Cinema in the Cemetery", "Mid: Anarchist Skill Share"]
  },
  {
    period: "June",
    events: ["Early: West Philly Porchfest", "Early: Fan Expo Philadelphia", "Early: Philadelphia Independent Film Festival", "End: Big Picture Film Fest", "End: Midsommar"]
  },
  {
    period: "July",
    events: ["Mid: Book Corner Summer Book Sale", "Mid: Blobfest", "End: Otakon (DC)"]
  },
  {
    period: "August",
    events: ["Early: BlackStar Film Festival", "HippoCamp (Conference for Creative Nonfiction Writers)", "Philly Naked Bike Ride", "Community Gardens Day"]
  },
  {
    period: "Fall",
    events: ["Early & Mid: Mushroom Foraging"]
  },
  {
    period: "September",
    events: ["Early: Hang Free Summer", "Mid: NYC Anarchist Bookfair", "End: Philadelphia Unnamed Film Festival", "End: 20/20 Photo Festival", "All: Philadelphia Fringe Festival"]
  },
  {
    period: "October",
    events: ["Early: Mad Poets Festival", "Early: Media Fright Fest Fall", "Mid: ecoWURD Environmental Justice Summit", "Mid: Book Corner Fall Book Sale", "End: Philadelphia Film Festival", "End: Exhumed Films 24 Hour Horror-thon", "End: Halloween Hikes and Hayrides in Fairmount Park", "End: Philly Zine Fest"]
  },
  {
    period: "November",
    events: ["Early: Philadelphia Asian American Film Festival", "End: Anime NYC"]
  },
  {
    period: "December",
    events: ["Early: Philly Zine Fest", "Early: Krampus Fest & Night Market", "Mid: Santa Lucia & Market + Julbord Dinner", "Mid: Fishtown Freeze Weekend"]
  }
];

export const BYWEEK = [
  {
    period: "First Mon",
    events: ["7:30pm: Philadelphia Psychotronic Film Society – Film (PhilaMOCA)"]
  },
  {
    period: "First Tues",
    events: ["slightly after sunset: Fairmount Park Conservancy – Stargazing at Lemon Hill"]
  },
  {
    period: "First Weds",
    events: ["7pm to 9pm: The Mad Poets Society – First Wednesdays"]
  },
  {
    period: "First Fri",
    events: ["8:30pm: The Late(ish) Poetry Show – Poem Readings (PhilaMOCA)"]
  },
  {
    period: "First Sat",
    events: ["10am: Fair Amount Food Forest – Monthly Work Party"]
  },
  {
    period: "First Weekend",
    events: ["10am: Philadelphia Mycology Club – Cobb’s Creek Cleanup"]
  },
  {
    period: "Second Weds",
    events: ["7pm to 9pm: The Mad Poets Society – Critique Circle"]
  },
  {
    period: "Second Thurs",
    events: ["8pm: The Bright Bulb Screening Series – Film (The Rotunda)", "9pm to 1am: Sanctorium Philly – Gothic Dance Party"]
  },
  {
    period: "Second Sat",
    events: ["5pm: Philadelphia Animation Society – Meeting (FU Church)"]
  },
  {
    period: "Mid Month",
    events: ["9pm: Full Moon Bike Ride – Ride (Art Museum)"]
  },
  {
    period: "Third Mon",
    events: ["7:30pm: Philadelphia Psychotronic Film Society – Film (PhilaMOCA)"]
  },
  {
    period: "Third Fri",
    events: ["8pm: Night Cycle – Ride (Varies)"]
  },
  {
    period: "Third Sat",
    events: ["10am to 12pm: Fairmount Park - Restoration Volunteer Workday"]
  },
  {
    period: "Last Mon",
    events: ["8pm: Horror Movie Mondays – Film (Underground Arts)"]
  },
  {
    period: "Last Tues",
    events: ["3pm to 7pm: Lancaster Avenue Autonomous Space – Library Hours"]
  },
  {
    period: "Last Thurs",
    events: ["9pm: The Gathering - Hip Hop, Breaking, Popping, Graff (The Rotunda, since 1996)"]
  },
  {
    period: "Last Sat",
    events: ["10am to 12:30am: Philly Free Market - Free Market (Malcom X Park)"]
  },
  {
    period: "Varying",
    events: ["varying: The Secret Cinema – Arthouse film showings"]
  },
];

export const BYDAY = [
  {
    period: "Mon",
    events: ["3pm to 5pm: Lancaster Avenue Autonomous Space – Food Share", "5pm to 8pm: Philadelphia Boardgame Nights – Game Night (Bourse Hall)"]
  },
  {
    period: "Tues",
    events: ["6:30pm: Neighborhood Bike Works – Bike Church (open shop)", "6pm to 9pm: Philadelphia Boardgame Nights – Game Night (The Ladder)"]
  },
  {
    period: "Weds",
    events: ["5pm to 9pm: Philly Social Adventures Happy Hour", "5pm to 9:30pm O.R.C.A. Open Hours"]
  },
  {
    period: "Thurs",
    events: ["3pm to 4pm: Lancaster Avenue Autonomous Space – Food Distro"]
  },
  {
    period: "Fri",
    events: ["8pm: DIY Shows at Over 40 Active Philly Spots - Shows", "3pm to 6pm: W Philly Food Not Bombs – Cook (Ahimsa House) & Serve (Malcom X)", "6:30pm: Neighborhood Bike Works – Bike Church (open shop)"]
  },
  {
    period: "Sat",
    events: ["9am to 12pm: Lancaster Avenue Autonomous Space – Really Really Free Market", "10am to 4pm: South Philly Food Not Bombs - Cook (People's Kitchen) & Serve (Mifflin Sqaure Park)", "10am: Wild Foodies of Philly – Foraging Hike"]
  },
  {
    period: "Sun",
    events: ["10am to 11am: Fairmount Park Conservancy – Yoga in the Park"]
  },
  {
    period: "Weekends",
    events: ["8pm: DIY Shows at Over 40 Active Philly Spots - Shows", "9am: BATONA Hiking Club of Philadelphia - Hike", "10am: New Jersey Mycological Association - Foray"]
  },
  {
    period: "Multiple Days",
    events: ["4pm to 9pm: Chibi Cafe – Various Events", "Varying: Hiking Around Philly – Hikes"]
  }
];
