export const VIDEOS = [
  "/videos/LOCAL_BAND_Zorn_Michief_Devils_Night_outdoor_local_10_30_2017_ewyG1lIOQOA.mp4",
  "/videos/LOCAL_BAND_Skullovich (St. Thrashricks Festival, Metro Gallery, Baltimore, Maryland, March 9th, 2024) [prRMf48IqO0].webm",
  "/videos/LOCAL_BAND_Timesup [McGe6Z5WFgg].webm",
  "/videos/LOCAL_BAND_Witching - Eschaton (Official Music Video) [20noD3q9mZg].webm",
  "/videos/LOCAL_BAND_NECROSEXUAL ＂DEAD SEXY＂ Music Video [OHV4Cz0NRuk].webm",
  "/videos/LOCAL_BAND_DEVIL MASTER - Acid Black Mass (Official Music Video) i-pqnibwdKw.webm",
  "/videos/LOCAL_BAND_Mannequin Pussy - ＂I Got Heaven＂ [EvgYIXc4JDA].webm",
  "/videos/LOCAL_BAND_SPITER ＂Tortured Soul＂ [3Ej32k_F5Hk].webm",
  "/videos/LOCAL_BAND_Doused - Hybrid Moments [fr7TBfCNccM].webm",
  "/videos/LOCAL_BAND_MORGUL BLADE - Heavy Metal Wraiths (OFFICIAL VIDEO) [MCnhn4kbst8].webm",
  "/videos/1672966024488308.webm",
  "/videos/1672484486295268.webm",
  "/videos/1672886949405656.webm",
  "/videos/1672484486295268.webm",
  "/videos/1675693367032483.webm",
  "/videos/1676331083776343.webm",
  "/videos/1676485111260617.webm",
  "/videos/1680978594314203.webm",
  "/videos/1681414082745190.webm",
  "/videos/1675693783392543.webm",
  "/videos/1676433843153554.webm",
  "/videos/1676701637804551.webm",
  "/videos/1682171337859088.webm",
  "/videos/1672886949405656.webm",
  "/videos/1675639522056373.webm",
  "/videos/1676314544468362.webm",
  "/videos/1676452976850334.webm",
  "/videos/1672966024488308.webm",
  "/videos/1674744712825460.webm",
  "/videos/1675647600437070.webm",
  "/videos/1676453038182319.webm",
  "/videos/1676941963391866.webm",
  "/videos/1678296718407930.webm",
  "/videos/1681253094497250.webm",
  "/videos/1674111016591414.webm",
  "/videos/1675693003630536.webm",
  "/videos/1676319458075522.webm",
  "/videos/1676941989515139.webm",
  "/videos/1679867231941941.webm",
  "/videos/1681336023706725.webm",
  "/videos/¡ Cathy Don t Go !  '666'-lknW2mzXMMY.mp4",
  "/videos/Men And Women [ytgqap7l6bg].webm",
  "/videos/clown town ｜ Memory Hole [fM5PJ_EDYEI].webm",
  "/videos/Neverending Hallowen [cNK31y-7QP0].webm",
  "/videos/THE DAWN OF THE PLANET OF THE POD PEOPLE-j0_TDPaUznw.mp4",
  "/videos/Cooped Up [dLCQGIwK-g0].webm",
  "/videos/McDrivin[mLRy_5U7rF8].mp4",
  "/videos/Oblivion NPC Dialogue - The Hole-O4h6f17CdrE.mp4",
  "/videos/dog_scum-mz8cLCTSLhU.mp4",
  "/videos/McFeels [DYSQd_JsltQ].mp4",
  "/videos/Grandmothers Big Day ｜ Memory Hole [y0GgZJidfUE].webm",
  "/videos/McFelon [cVsQyRiGxsk].mp4",
  "/videos/rot [sDF4CXawz3w].webm",
  "/videos/Wrong Way-Memory Hole [cqv40vvx_rA].webm",
  "/videos/1682738409080161.webm",
  "/videos/1681116699576155.webm",
  "/videos/1467619454680.webm",
  "/videos/1608984609784.webm",
  "/videos/1611562368891.webm",
  "/videos/1623278661919.webm",
  "/videos/1654503960459.webm",
  "/videos/1658137961903410.webm",
  "/videos/1663213103347792.webm",
  "/videos/1488261293042.webm",
  "/videos/1607763069943.webm",
  "/videos/1619401095909.webm",
  "/videos/1623281629534.webm",
  "/videos/1623784615286.webm",
  "/videos/1655132148448.webm",
  "/videos/1658464824058499.webm",
  "/videos/1663213230996229.webm",
  "/videos/1492887951671.webm",
  "/videos/1608109383959.webm",
  "/videos/1609982614399.webm",
  "/videos/1620105652965.webm",
  "/videos/1623321699096.webm",
  "/videos/1623784830416.webm",
  "/videos/1655458048207.webm",
  "/videos/1662754209931412.webm",
  "/videos/1663321372386276.webm",
  "/videos/1603330722344.webm",
  "/videos/1608480457053.webm",
  "/videos/1610396366907.webm",
  "/videos/1620446443306.webm",
  "/videos/1623336011344.webm",
  "/videos/1623791458074.webm",
  "/videos/1657227752388.webm",
  "/videos/1663365427563921.webm",
  "/videos/1608939517243.webm",
  "/videos/1610572177171.webm",
  "/videos/1615349267636.webm",
  "/videos/1623105050274.webm",
  "/videos/1623358821192.webm",
  "/videos/1628051359548.webm",
  "/videos/1657571012609.webm",
  "/videos/1663098643465792.webm",
  "/videos/1663417233530871.webm",
  "/videos/1608984375944.webm",
  "/videos/1610706355255.webm",
  "/videos/1615764673153.webm",
  "/videos/1623219964463.webm",
  "/videos/1623768810033.webm",
  "/videos/1634176384292.webm",
  "/videos/1658051901194.webm",
  "/videos/1663192461506106.webm",
  "/videos/1663417450659593.webm",
  "/videos/1456153004237.webm",
  "/videos/1496373745991.webm",
  "/videos/1499197763145.webm",
  "/videos/1499203717145.webm",
  "/videos/1505921822132.webm",
  "/videos/1456303492138.webm",
  "/videos/1496422755393.webm",
  "/videos/1496952486798.webm",
  "/videos/1525133923319.webm",
  "/videos/1591440922764.webm",
  "/videos/feralhuman.mp4",
  "/videos/1494336836963.webm",
  "/videos/1496664147328.webm",
  "/videos/1496999776018.webm",
  "/videos/1499200419678.webm",
  "/videos/1503703208715.webm",
  "/videos/1537769549304.webm",
  "/videos/1553080943997.webm",
  "/videos/1593640497030.webm",
  "/videos/1496730955127.webm",
  "/videos/1499201947145.webm",
  "/videos/1504481085856.webm",
  "/videos/1519013620333.webm",
  "/videos/1538020928604.webm",
  "/videos/1553320390748.webm",
  "/videos/1601355422376.webm",
  "/videos/sylvestartstillalone.webm",
  "/videos/1495188322657.webm",
  "/videos/1496853320700.webm",
  "/videos/1499086397207.webm",
  "/videos/1499203197145.webm",
  "/videos/1504649443642.webm",
  "/videos/1543768486877.webm",
  "/videos/1574501293082.webm",
  "/videos/1602691705092.webm",
  "/videos/1496342255421.webm",
  "/videos/1496869090821.webm",
  "/videos/1499134779901.webm",
  "/videos/1499203561145.webm",
  "/videos/1504666251689.webm",
  "/videos/1545652717403.webm",
  "/videos/1575331141585.webm",
  "/videos/1602692722092.webm",
  "/videos/1683393702542650.webm",
  "/videos/1680658438907267.webm",
  "/videos/1685390101277878.webm",
  "/videos/1685466002432144.webm",
  "/videos/1684107993492093.webm",
  "videos/A day in the life of a Japanese alcoholic [pO484lp33q8].webm"
];
