export const VIDEOSLOCALINFO = [
  {
    "filename": "LOCAL_BAND_Zorn_Michief_Devils_Night_outdoor_local_10_30_2017_ewyG1lIOQOA",
    "band": "Zorn - Mischief / Devil’s Night",
    "bandcamp": "https://zornphilly.bandcamp.com/music",
    "social": "https://www.instagram.com/zorn_philly/",
    "videographer": "@dstarfreestar",
    "videographerLink": "https://www.youtube.com/watch?v=ewyG1lIOQOA",
  },
  {
    "filename": "LOCAL_BAND_DEVIL MASTER - Acid Black Mass (Official Music Video) i-pqnibwdKw",
    "band": "Devil Master - Acid Black Mass",
    "bandcamp": "https://devilmaster.bandcamp.com/music",
    "social": "https://www.facebook.com/devilisyourmaster",
    "videographer": "@Erica Frevel",
    "videographerLink": "https://www.youtube.com/watch?v=i-pqnibwdKw",
  },
  {
    "filename": "LOCAL_BAND_Skullovich (St. Thrashricks Festival, Metro Gallery, Baltimore, Maryland, March 9th, 2024) [prRMf48IqO0]",
    "band": "Skullovich",
    "bandcamp": "https://skullovich.bandcamp.com/music",
    "social": "https://www.instagram.com/skullovich_official/",
    "videographer": "@The Renaissance Blann",
    "videographerLink": "https://www.youtube.com/watch?v=prRMf48IqO0",
  },
  {
    "filename": "LOCAL_BAND_Timesup [McGe6Z5WFgg]",
    "band": "OK Buddy - Timesup",
    "bandcamp": "https://okbuddy.bandcamp.com/",
    "social": "https://www.instagram.com/okbuddy.pa/",
  },
  {
    "filename": "LOCAL_BAND_Witching - Eschaton (Official Music Video) [20noD3q9mZg]",
    "band": "Witching - Eschaton",
    "bandcamp": "https://witching.bandcamp.com/music",
    "social": "https://www.instagram.com/witching_philly/",
  },
  {
    "filename": "LOCAL_BAND_Mannequin Pussy - ＂I Got Heaven＂ [EvgYIXc4JDA]",
    "band": "Mannequin Pussy - I Got Heaven",
    "bandcamp": "https://mannequinpussy.bandcamp.com/music",
    "social": "https://www.instagram.com/mannequinpussy/",
  },
  {
    "filename": "LOCAL_BAND_NECROSEXUAL ＂DEAD SEXY＂ Music Video [OHV4Cz0NRuk]",
    "band": "Necro Sexual - Dead Sexy",
    "bandcamp": "https://necrosexual.bandcamp.com/music",
    "social": "https://www.instagram.com/thenecrosexual/",
  },
  {
    "filename": "LOCAL_BAND_SPITER ＂Tortured Soul＂ [3Ej32k_F5Hk]",
    "band": "Spiter - Tortured Soul",
    "bandcamp": "https://spiter.bandcamp.com/music",
    "social": "https://www.instagram.com/spiter666/",
  },
  {
    "filename": "LOCAL_BAND_Doused - Hybrid Moments [fr7TBfCNccM]",
    "band": "Doused - Hybrid Moments",
    "bandcamp": "https://dousedphl.bandcamp.com/music",
    "social": "https://www.instagram.com/dousedphl",
  },
  {
    "filename": "LOCAL_BAND_MORGUL BLADE - Heavy Metal Wraiths (OFFICIAL VIDEO) [MCnhn4kbst8]",
    "band": "Morgul Blade - Heavy Metal Wraiths",
    "bandcamp": "https://morgulblade.bandcamp.com/music",
    "social": "https://www.instagram.com/morgul_blade_hm/",
  },
  {
    "filename": "LOCAL_BAND_Knuckle Deep - Gaslight LIVE! At The Crawlspace (9.18.21) [lW39elk-NcQ]",
    "band": "Knuckle Deep - Gaslight",
    "bandcamp": "https://knuckledeep.bandcamp.com/music",
    "social": "https://www.instagram.com/knuckledeeptv/",
  },
]
